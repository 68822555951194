<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-card

        >
          <b-form
                  @submit.prevent="searchByCondition"
                  @reset.prevent="resetCondition"
          >
            <b-row>
              <!-- 查询条件     -->
              <!--生产编号-->
              <b-col
                      cols="12"
                      md="4"
              >
                <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="生产编号:"
                        label-for="produce_no"
                        label-size="sm"

                >
                  <b-form-input
                          v-model="state.condition.produce_no"
                          name="produce_no"
                          class="d-inline-block mr-1"
                          size="sm"
                          placeholder="生产编号"
                  />
                </b-form-group>
              </b-col>
              <!--生产仓库-->
              <b-col
                      cols="12"
                      md="4"
                      class="mb-2"
              >
                <xy-input-button
                        label="生产仓库"
                        type="input"
                        v-on:change="fromChildren($event,['warehouse_name','warehouse_id'])"
                        :params="['warehouse_name','warehouse_id']"
                        :value="state.condition.warehouse_name"
                        modalName="仓库"
                        placeholder="点击选择仓库"
                >
                </xy-input-button>
              </b-col>

              <!--交货日期-->
              <b-col md="4" cols="12">
                <xy-input-button label="交货日期"
                                 type="time"
                                 v-on:change="fromChildren($event,['delivery_date'])"
                                 :params="['delivery_date']"
                                 :value="state.condition.delivery_date"
                >
                </xy-input-button>
              </b-col>

              <!--标题-->
              <b-col
                      cols="12"
                      md="4"
              >
                <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="标题:"
                        label-for="title"
                        label-size="sm"

                >
                  <b-form-input
                          v-model="state.condition.title"
                          name="title"
                          class="d-inline-block mr-1"
                          size="sm"
                          placeholder="标题"
                  />
                </b-form-group>
              </b-col>

              <!--     创建人         -->
              <b-col
                      cols="12"
                      md="4"
                      class="mb-2"
              >
                <xy-input-button
                        label="创建人"
                        type="input"
                        v-on:change="fromChildren($event,['creator_name','creator'])"
                        :params="['creator_name','creator']"
                        :value="state.condition.creator_name"
                        modalName="创建人"
                        placeholder="点击选择创建人"
                >
                </xy-input-button>
              </b-col>

              <!--状态-->
              <b-col
                      cols="12"
                      md="4"
              >
                <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="生产状态:"
                        label-for="order_status"
                        label-size="sm"
                >
                  <v-select
                          id="status"
                          :options="getCodeOptions('bom_produce_status')"
                          v-model="state.condition.status"
                          name="order_status"
                          class="select-size-sm"
                          placeholder="请选择状态"
                          :reduce="item =>item.value"
                  />
                </b-form-group>
              </b-col>


              <!--主成品-->
              <b-col
                      cols="12"
                      md="4"
              >
                <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="商品名称:"
                        label-for="product_name"
                        label-size="sm"

                >
                  <b-form-input
                          v-model="state.condition.productCondition"
                          name="product_name"
                          class="d-inline-block mr-1"
                          size="sm"
                          placeholder="商品名称或69码"
                  />
                </b-form-group>
              </b-col>



              <b-col
                      cols="12"
                      md="12"
                      class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>

                <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>

                <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        @click="exportData()"
                >
                  <span class="align-right">导出</span>
                </b-button>



              </b-col>
            </b-row>
          </b-form>
        </b-card>
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
              v-model="limit"
              :options="limitOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>


        </b-row>

      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="searchList"
        responsive
        hover
        small
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="orderBy"
        show-empty
        empty-text="未找到记录"
        :sort-desc.sync="isSortDirDesc"
      >

        <template #row-details="row">
          <!--<b-card v-for="i in row.item.ext.items" :key="i.name">-->
          <b-card>
            <div>
              <b-table striped hover :items="row.item.ext.items" :fields="fields">
                <!-- :readonly="data.item.refuse_qty>0"-->

                <template #cell(type)="data">
                  {{ isNumber(data.item.ext.type)=== 1 ?'主原料':(isNumber(data.item.ext.type)=== 2 ? '副成品':'主成品')}}
                </template>

                <template #cell(true_qty)="data">
                  {{ isNumber(data.item.ext.true_qty)}}
                </template>

                <template #cell(plan_qty)="data">
                  {{ isNumber(data.item.ext.plan_qty)}}
                </template>

                <template #cell(cost)="data">
                  {{ isNumber(data.item.ext.cost)===0?"":addNumber(data.item.ext.cost,0)}}
                </template>

                <template #cell(subTotal)="data">
                  {{ isNumber(data.item.ext.subTotal)===0?"":addNumber(data.item.ext.subTotal,0)}}
                </template>

                <!--  <template #cell(grossProfit)="data">
                    <span>{{ data.item.ext.grossProfit }}</span>
                  </template>-->

              </b-table>
            </div>
          </b-card>
        </template>
        <!-- Columns -->
        <template #cell(id)="data">
          <b-form-checkbox v-model="data.detailsShowing"
                           name="check-button"
                           @change="data.toggleDetails"
                           button-variant="default"
                           size="sm"
                           button>
            <feather-icon
                    icon="MinusSquareIcon"
                    size="15"
                    class="align-middle text-body"
                    v-show="data.detailsShowing==true"
            />
            <feather-icon
                    icon="PlusSquareIcon"
                    size="16"
                    class="align-middle text-body"
                    v-show="data.detailsShowing==false"
            />
          </b-form-checkbox>
          [#{{ data.item.id }}]
        </template>

        <!-- Column: Type -->
        <template #cell(creator)="data">
          {{ getCodeLabel('user',data.item.creator)}} {{toTime((data.item.create_time))}}
        </template>

        <template #cell(updator)="data">
          {{getCodeLabel('user', data.item.updator)}} {{toTime((data.item.update_time))}}
        </template>

        <template #cell(delivery_date)="data">
          {{toDate((data.item.delivery_date))}}
        </template>

        <template #cell(status)="data">
          {{getCodeLabel('bom_produce_status', data.item.status)}}
        </template>

        <!-- Column: State -->


        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <div v-if="data.item.status == 1">
              <b-dropdown-item @click="changeStatus(data.item.id,2)" >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">提交审核</span>
              </b-dropdown-item>

              <b-dropdown-item @click="changeState(data.item)" >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">删除</span>
              </b-dropdown-item>
            </div>

            <div v-if="data.item.status == 4">
              <b-dropdown-item  :to="{ name: 'apps-bomproduceorder-edit', query: { id: data.item.id, onlyRead:false } }">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">浏览</span>
              </b-dropdown-item>
            </div>

            <!--生产中-->
            <div v-if="data.item.status == 2">
              <b-dropdown-item @click="changeStatus(data.item.id,1)" >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">驳回</span>
              </b-dropdown-item>
            </div>


            <b-dropdown-item v-if="data.item.status !=4" :to="{ name: 'apps-bomproduceorder-edit', query: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>


          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="start"
              :total-rows="listTotals"
              :per-page="limit"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted, reactive, onMounted} from '@vue/composition-api'
import { avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor,isNumber,isEmpty ,addNumber} from '@core/utils/filter'
import bomproduceorderUseList from './bomproduceorderUseList'
import bomproduceorderStore from './bomproduceorderStore'
import XyInputButton from "@/views/components/xy/XyInputButton";
import {useToast} from "vue-toastification/composition";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    XyInputButton
  },
  methods: {

    searchByCondition() {
      this.refetchData()
    },
    resetCondition() {
      this.state.condition = {}
      store.commit('bomproduceorder/updateCondition', this.state.condition)
      this.refetchData()
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'type',
          label: '科目',
        },
        {
          key: 'name',
          label: '物料名称',
        },
        {
          key: 'code',
          label: '69码',
        },
        {
          key: 'box_quantity',
          label: '单份规格数量',
        },
        {
          key: 'plan_qty',
          label: '计划数量',
        },
        {
          key: 'true_qty',
          label: '实际数量',
        },
        {
          key: 'cost',
          label: '成本价',
        },
        {
          key: 'subTotal',
          label: '总成本',
        },

      ],
    }
  },

  setup() {
    // Register module

    const toast = useToast()
    if (!store.hasModule('bomproduceorder')) store.registerModule('bomproduceorder', bomproduceorderStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('bomproduceorder')) store.unregisterModule('bomproduceorder')
    })

    const state = reactive({
      condition: {},
    })

    onMounted(() => {
      state.condition = store.getters['bomproduceorder/getCondition']
    })

    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        this.state.condition[modal[i]] = params == null ? null : params[modal[i]]
      }
      this.$forceUpdate()
    }

    const changeStatus = function (id,status) {
      store.dispatch('bomproduceorder/changeStatus', {
                id:id,
                status:status
              }
      ).then( res =>{
        if (res.data.code === 0) {
          toast.success('数据已保存!')
          refetchData()
        }else {
          toast.error('数据保存异常，'+ res.data.data)
        }
      })
    }

    const  exportData = function () {
      const condition = {...store.getters['bomproduceorder/getCondition']}
      const params = {
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':''
      }
      Object.assign(params,condition)
      store
              .dispatch('bomproduceorder/exportToExcel', params)
              .then(res => {
                toast.success("导出成功")
                let data = new Blob([res.data], { type: 'application/vnd.ms-excel' })   //接收的是blob，若接收的是文件流，需要转化一下
                let filename = "bom生产单-"+new Date().getTime();
                /*-------------*/
                const link = document.createElement('a')
                link.style.display = 'none'
                link.href = URL.createObjectURL(data);
                link.setAttribute('download',filename+'.xlsx')
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
              })
    }


    const   changeState =  function(data) {

      const state = 0
      store.dispatch('bomproduceorder/state', {id: data.id, state: state}).then(res => {
        toast.success('删除成功!')
        refetchData()
      })
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = bomproduceorderUseList()

    return {
      isNumber,
      isEmpty,
      state,
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
     toTime, 
     toDate,
      fromChildren,
      changeStatus,
      exportData,
     changeState,
      addNumber
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
